<template>
    <div class="row">
        <div class="col-xxl-4" v-for="i in items" v-bind:key="i">
            <b-card class="card-stretch gutter-b">
                <b-skeleton width="85%" class="mb-3"></b-skeleton>
                <b-skeleton width="55%" class="mb-3"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
            </b-card>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: 3
        }
    }
}
</script>
