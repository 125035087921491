<template>
    <div>
        <b-table
            id="actionTable"
            class="m-0 text-center"
            :sort-desc.sync="dir"
            :items="items"
            :fields="fields"
            :sort-by.sync="sort"
            :per-page="perPage"
            :current-page.sync="currentPage"
            foot-clone
            hover
            responsive
        >
            <template v-slot:cell(isValid)="data">
                <span
                    class="badge mx-1 my-1 badge-primary"
                    :class="isClosed(data.item.end) ? 'badge-primary' : data.item.isValid ? 'badge-success' : 'badge-warning'"
                >
                    {{
                        isClosed(data.item.end)
                            ? $t('ACTION.GENERAL.CLOSED')
                            : data.item.isValid
                            ? $t('ACTION.GENERAL.IS_VALID')
                            : $t('ACTION.GENERAL.NOT_CLOSED')
                    }}
                </span>
            </template>
            <template v-slot:cell(name)="data">
                <div class="font-weight-bold text-dark-75">
                    <router-link
                        v-if="role !== 'Stagiaire' && isClosed(data.item.end)"
                        :to="getLink(data.item)"
                    >
                        {{ data.item.name }}
                    </router-link>
                    <span v-else>{{ data.item.name }}</span>
                </div>
                <span class="text-dark-50">{{ data.item.zip_code }} {{ data.item.city }}</span>
            </template>
            <template v-slot:cell(ht)="data" :data-sorter="data.item.ht">
                <span v-if="data.item.facturation === 0">{{ data.item.ht | toCurrency }}</span>
                <span v-else-if="data.item.facturation === 1">{{ data.item.ht_gfc | toCurrency }}</span>
            </template>
            <template v-slot:cell(start)="data" :data-sorter="data.item.start">
                <template v-if="data.item.start">
                    {{ data.item.start | moment('Do MMMM YYYY') }}
                </template>
            </template>
            <template v-slot:cell(end)="data" :data-sorter="data.item.end">
                <template v-if="data.item.end">
                    {{ data.item.end | moment('Do MMMM YYYY') }}
                </template>
            </template>
            <template v-slot:cell(certificate_url)="data">
                <a
                    v-if="data.item.certificate_url && new Date() >= addDays(data.item.end, 5)"
                    :href="data.item.certificate_url"
                    class="font-weight-bolder"
                    target="_blank"
                >
                    {{ $t('GENERAL.DOWNLOAD') }}
                </a>
                <span v-else v-b-tooltip.hover :title="tooltipMessage(data.item.end)" class="font-weight-bolder text-info">
                    <i class="fas fa-info-circle text-info"></i>
                </span>
            </template>
            <template v-slot:cell(certificate_url_users)="data">
                <a
                    v-if="data.item.certificate_url_users"
                    :href="data.item.certificate_url_users"
                    class="font-weight-bolder"
                    target="_blank"
                >
                    {{ $t('GENERAL.DOWNLOAD') }}
                </a>
                <span v-else v-b-tooltip.hover :title="tooltipMessage(data.item.end)" class="font-weight-bolder text-info">
                    <i class="fas fa-info-circle text-info"></i>
                </span>
            </template>
            <template v-slot:cell(time_card_url)="data">
                <a
                    v-if="data.item.time_card_url && new Date() >= addDays(data.item.end, 5)"
                    :href="data.item.time_card_url"
                    class="font-weight-bolder"
                    target="_blank"
                >
                    {{ $t('GENERAL.DOWNLOAD') }}
                </a>
                <span v-else v-b-tooltip.hover :title="tooltipMessage(data.item.end)" class="font-weight-bolder text-info">
                    <i class="fas fa-info-circle text-info"></i>
                </span>
            </template>
            <template v-slot:cell(convocations_url)="data">
                <a
                    v-if="data.item.convocations_url"
                    :href="data.item.convocations_url"
                    class="font-weight-bolder"
                    target="_blank"
                >
                    {{ $t('GENERAL.DOWNLOAD') }}
                </a>
                <span v-else v-b-tooltip.hover title="Indisponible pour le moment." class="font-weight-bolder text-info">
                    <i class="fas fa-info-circle text-info"></i>
                </span>
            </template>
        </b-table>
        <div class="d-flex justify-content-center align-items-center">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                aria-controls="actionTable"
                size="lg"
                class="mt-5"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
import store from "@/core/services/store";
import { mapGetters } from 'vuex'
import moment from 'moment/moment'
export default {
    props: ['sortBy', 'stagiaire'],
    data() {
        return {
            dir: true,
            sort: this.sortBy,
            perPage: 9,
            currentPage: 1,
            fields: [
                { key: 'name', label: this.$t('ACTION.GENERAL.NAME_AND_CITY'), sortable: true },
                { key: 'customer', label: this.$t('ACTION.GENERAL.CUSTOMER'), sortable: true },
                { key: 'start', label: this.$t('ACTION.GENERAL.START'), sortable: true },
                { key: 'end', label: this.$t('ACTION.GENERAL.END'), sortable: true },
                {
                    key: 'certificate_url_users',
                    label: this.$t('ACTION.GENERAL.CERTIFICATE_URL_USERS'),
                    sortable: false
                }
            ],
            items: [],
            role: null
        }
    },
    mounted() {
        this.role = store.getters.getRole.name
        if (store.getters.getRole.name !== 'Stagiaire' && this.$route.name !== 'intern.view') {
            this.fields.push({ key: 'isValid', label: this.$t('ACTION.GENERAL.IS_VALID'), sortable: true })
        }
        if (this.$route.name === 'action.closed') {
            this.fields.splice(this.fields.length - 1, 0, { key: 'certificate_url', label: this.$t('ACTION.GENERAL.CERTIFICATE_URL'), sortable: false })
        } else {
            //ajouté en avant dernier 
            this.fields.splice(this.fields.length - 1, 0, { key: 'convocations_url', label: this.$t('ACTION.GENERAL.CONVOCATIONS_URL'), sortable: false })
        }
        this.actions &&
            this.actions.map(action =>
                this.items.push({
                    id: action.act_id,
                    city: action.act_adr_ville,
                    name: action.acl_pro_libelle,
                    zip_code: action.act_adr_cp,
                    closed: action.closed,
                    isValid: action.acl_confirme,
                    facturation: action.acl_facturation,
                    ht: action.acl_ca,
                    ht_gfc: action.acl_ca_gfc,
                    customer: action.name,
                    start: action.date_deb,
                    end: action.date_end,
                    certificate_url: action.certificate_url,
                    certificate_url_users: action.certificate_url_users,
                    convocations_url: action.convocations_url,
                    time_card_url: action.time_card_url,
                    societe: action.act_soc,
                    acl_id: action.acl_id
                })
            )
        this.items.forEach(item => {
            item.name = item.name.replace('<b>', '').replace('</b>', '')
        })
    },
    methods: {
        isClosed(dateFin) {
            if (moment(dateFin).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                return true
            }
        },
        getLink(item) {
            return {
                name: 'action.view',
                params: { id: item.acl_id, societe: item.societe }
            }
        },
        tooltipMessage(date) {
            if (
                moment(date)
                    .add(5, 'days')
                    .format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
            ) {
                return (
                    'Dossier administratif en cours de traitement. Les documents seront disponibles à partir du ' +
                    moment(date)
                        .add(5, 'days')
                        .format('DD/MM/YYYY')
                )
            } else {
                return 'En cours de traitement administratif. En cas de besoin,contacter votre interlocuteur·rice habituel·le'
            }
        },
        addDays(date, days) {
            var result = new Date(date)
            result.setDate(result.getDate() + days)
            //return date in French format
            return result.toLocaleDateString('fr-FR')
        }
    },
    watch: {
        sort: function(newValue) {
            this.$emit('change-sort', {
                sortBy: newValue,
                dir: this.dir
            })
        },
        dir: function(newValue) {
            this.$emit('change-sort', {
                sortBy: this.sort,
                dir: newValue
            })
        }
    },
    computed: {
        ...mapGetters({
            actions: 'listActions',
            currentUser: 'currentUser'
        })
    }
}
</script>
